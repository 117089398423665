<template>
  <div>
    <div class="leftBox">
      <div
        class="li"
        :class="index == listOn ? 'on' : ''"
        v-for="(item, index) in list"
        @click="listClick(index, item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      listOn: 0,
      list: [
        {
          name: "个人中心",
          id: "people",
        },
        {
          name: "我的订单",
          id: "order",
        },
        {
          name: "我的收藏",
          id: "shoucang",
        },
        {
          name: "我的地址",
          id: "addressCont",
        },
        {
          name: "产品评价",
          id: "orderPl",
        },
      ],
    };
  },
  created() {},
  methods: {
    listClick(i, item) {
      this.$router.push({
        path: "/user/" + item.id,
        query: {},
      });
      this.listOn = i;
    },
  },
  mounted() {
    for (let i = 0; i < this.list.length; i++) {
      const e = this.list[i];
      let path = "/user/" + e.id;
      if (path == this.$route.path) {
        this.listOn = i;
      }
    }
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.leftBox {
  width: 194px;
  background-color: #fff;
  padding: 24px 0 20px 43px;
  margin-right: 10px;
  min-height: 504px;
  max-height: 90vh;
  border-radius: 6px;
  overflow-y: auto;
  float: left;
  border-radius: 5px;
  .li {
    height: 46px;
    cursor: pointer;
    line-height: 46px;
  }
  .on {
    color: #ee2223;
  }
}
.ritBox {
  float: left;
  width: calc(100% - 204px);
}
</style>
