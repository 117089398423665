<template>
  <div>
    <div class="content">
      <UserList />
      <div class="ritBox">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
import UserList from "../component/userList.vue";

export default {
  components: {
    UserList,
  },
  name: "",
  data() {
    return {
      listOn: 0,
      list: [
        {
          name: "个人中心",
          id: "people",
        },
        {
          name: "我的收藏",
          id: "shoucang",
        },
      ],
    };
  },
  watch: {
    $route (val) {
      // console.log(val);
    }
  },
  created() {},
  methods: {
    listClick(i, item) {
      this.$router.push({
        path: "/user/" + item.id,
        query: {},
      });
      this.listOn = i;
    },
  },
  actived(){
    console.log(this.$route.fullPath);
  },
  mounted() {},
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 1440px;
  margin: auto;
  box-sizing: border-box;
  overflow: hidden;
  .leftBox {
    width: 194px;
    background-color: #fff;
    padding: 24px 0 20px 43px;
    margin-right: 10px;
    min-height: 504px;
    max-height: 90vh;
    border-radius: 6px;
    overflow-y: auto;
    float: left;
    border-radius: 5px;
    .li {
      height: 46px;
      cursor: pointer;
      line-height: 46px;
    }
    .on {
      color: #ee2223;
    }
  }
  .ritBox {
    float: left;
    width: calc(100% - 204px);
  }
}
</style>
